import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"

const Contact = props => {
  const [mailSent, setmailSent] = useState(false)
  const [error, setError] = useState(null)
  const [formData, setFormData] = useState({})

  const handleFormSubmit = e => {
    e.preventDefault()
    console.log(formData)
    axios({
      method: "post",
      url: "https://asccr.org/api/contact.php",
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then(result => {
        if (result.data.sent) {
          setmailSent(result.data.sent)
          setError(false)
        } else {
          setError(true)
        }
      })
      .catch(error => setError(error.message))
  }

  const handleChange = (e, field) => {
    let value = e.target.value
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  const {
    title,
    description,
    successMessage,
    errorMessage,
    fieldsConfig,
  } = props.config
  return (
    <div className="contact-form m-20">
      <div className="contact-form__header">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="contact-form__container">
        <div>
          <form action="#">
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? (
                      <React.Fragment>
                        <label className="block px-3 py-2 text-green-800">
                          {field.label}
                        </label>
                        <input
                          type={field.type}
                          className="block bg-green-200 px-3 py-2 text-green-900 rounded"
                          placeholder={field.placeholder}
                          value={field.name}
                          name={field.fieldName}
                          onChange={e => handleChange(e, field.fieldName)}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <label className="block px-3 py-2 text-green-800">
                          {field.label}
                        </label>
                        <textarea
                          className="block bg-green-200 px-3 py-2 text-green-900 rounded"
                          placeholder={field.placeholder}
                          onChange={e => handleChange(e, field.fieldName)}
                          value={field.name}
                          name={field.fieldName}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              })}
            <input
              type="submit"
              onClick={e => handleFormSubmit(e)}
              value="Submit"
              className="bg-green-300 text-green-900 px-3 py-2 rounded block mt-2"
            />
            <div>
              {mailSent && <div className="sucsess">{successMessage}</div>}
              {error && <div className="error">{errorMessage}</div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact
//propTypes for the component
Contact.propTypes = {
  config: PropTypes.object.isRequired,
}
