import React from "react"
import Form from "../components/form"

const Contact = () => {
  const config = {
    api: "https://asccr.org/api/contact.php",
    title: "Contact Me",
    description:
      "Write us about your request, We'll get back to you within 24 hours.",
    successMessage: "Thank you for contcting me.",
    errorMessage: "Please Fill the complete form",
    fields: {
      firstName: "",
      lastName: "",
      email: "",
      msg: "",
    },
    fieldsConfig: [
      {
        id: 1,
        label: "First Name",
        fieldName: "firstName",
        type: "text",
        placeholder: "Your First Name",
        isRequired: true,
        klassName: "first-name-field",
      },
      {
        id: 2,
        label: "Last Name",
        fieldName: "lastName",
        type: "text",
        placeholder: "Your Last Name",
        isRequired: true,
        klassName: "last-name-field",
      },
      {
        id: 3,
        label: "Email",
        fieldName: "email",
        type: "email",
        placeholder: " Your Email",
        isRequired: true,
        klassName: "email-field",
      },
      {
        id: 4,
        label: "Message",
        fieldName: "msg",
        type: "textarea",
        placeholder: "Write something.....",
        isRequired: true,
        klassName: "message-field",
      },
    ],
  }
  return (
    <div>
      <Form config={config} />
    </div>
  )
}

export default Contact
